import request from '@/utils/request'

/**
 * 保存门店收入收支
 * @param data
 * @returns {AxiosPromise}
 */
export function saveStoreAccounting(data) {
    return request({
        url: '/sys/storeAccounting',
        method: 'post',
        data: data,
    })
}

/**
 * 分页查询
 * @param parms
 * @returns {AxiosPromise}
 */
export function storeAccountingPage(parms) {
    return request({
        url: '/sys/storeAccounting/page',
        method: 'get',
        params: parms,
    })
}

export function getStoreAccounting(accountingId) {
    return request({
        url: '/sys/storeAccounting/' + accountingId,
        method: 'get'
    })
}

/**
 * 保存门店收入收支
 * @param data
 * @returns {AxiosPromise}
 */
export function updateStoreAccounting(data) {
    return request({
        url: '/sys/storeAccounting',
        method: 'put',
        data: data,
    })
}
// 门店财务对账列表查询
export function getMonthlyFinancialStatement(params) {
    return request({
        url: '/order/financialBill/getMonthlyFinancialStatement',
        method: 'get',
        params,
    })
}
// 分页查询门店财务对账单洗涤费明细
export function getFinancialBillWashFee(params) {
    return request({
        url: '/order/financialBill/washFee/page',
        method: 'get',
        params,
    })
}
// 分页查询财务对账办卡/充值明细
export function getFinancialBillCardFee(params) {
    return request({
        url: '/order/financialBill/card/page',
        method: 'get',
        params,
    })
}
// 分页查询对账单通享余额消费明细
export function getFinancialBillUniversalFee(params) {
    return request({
        url: '/order/financialBill/universalFee/page',
        method: 'get',
        params,
    })
}
// 分页查询对账单美团明细
export function getFinancialBillMeituanFee(params) {
    return request({
        url: '/order/financialBill/meituanFee/page',
        method: 'get',
        params,
    })
}
// 分页查询对账单小程序付款明细
export function getFinancialBillAppletFee(params) {
    return request({
        url: '/order/financialBill/appletFee/page',
        method: 'get',
        params,
    })
}
// 分页查询对账单转余额明细
export function getFinancialBillTransFee(params) {
    return request({
        url: '/order/financialBill/transFee/page',
        method: 'get',
        params,
    })
}
// 门店财务对账详情查看
export function getFinancialStatementDetail(params) {
    return request({
        url: '/order/financialBill/getCashierEndFinancialDetail',
        method: 'get',
        params,
    })
}
// 修改财务对账单状态
export function updateFinancialBillStatus(data) {
    return request({
        url: '/order/financialBill/change/status',
        method: 'post',
        data,
    })
}
// 校验门店密码
export function checkStorePass(params) {
    return request({
        url: '/sys/store/checkStorePass',
        method: 'get',
        params,
    })
}
// 导出门店对账单洗涤费明细
export function exportWashFeel(params) {
    return request({
      url: `/order/bill/wash_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }
  // 导出对账单通享余额消费明细
  export function exportUniversalFeel(params) {
    return request({
      url: `/order/bill/universal_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }
  // 导出财务办卡/充值明细
  export function exportCardFeel(params) {
    return request({
      url: `/order/bill/card_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }
  // 导出对账单美团明细
  export function exportMeituanFeel(params) {
    return request({
      url: `/order/bill/meituan_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }
  // 导出对账单转余额明细
  export function exportTransferBalanceFeel(params) {
    return request({
      url: `/order/bill/transfer_balance_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }
  // 导出对账单小程序付款明细
  export function exportAppletFeel(params) {
    return request({
      url: `/order/bill/applet_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }
  // 分页查询对账单抖音明细
export function getTiktokFee(params) {
    return request({
      url: '/order/bill/tiktok_fee/page',
      method: 'get',
      params,
    })
  }
// 导出对账单抖音明细
export function exportTiktokFee(params) {
    return request({
      url: `/order/bill/tiktok_fee/export`,
      method: 'get',
      responseType: 'blob',
      params,
    })
  }