<template>
    <div class="popView" style="left: 0px; width: 100%;" v-if="show">
        <div class="popView-bcView"></div>
        <div class="popView-contentView"
             :style="{'top': top,'right':right, 'width': width, 'max-height': '80%', 'border-radius': '6px', 'background': 'rgb(255, 255, 255)', 'overflow': 'auto', 'box-shadow':'rgb(51, 51, 51) 0px 0px 20px 0px'}">
            <div class="popView-headerView">{{title}}</div>
            <slot></slot>
            <button class="btn-close" @click="cancel"></button>
        </div>

    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            //弹窗组件是否显示 默认不显示 必传属性
            show: {
                type: Boolean,
                default: false,
                required: true,
            },
            top: {
                type: String,
                default: '15%',
            },
            right: {
                type: String,
                default: '0',
            },
            width: {
                type: String,
                default: '400px',
            },
            title: {
                type: String,
                default: '系统提示',
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
            },
        }
    }
</script>

<style scoped lang="scss">

    .popView {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;

        > .popView-bcView {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
        }

        > .popView-contentView {
            position: absolute;
            border-radius: 6px;
            left: 0;
            right: 0;
            margin: auto;
            min-height: 200px;
            overflow-x: hidden;
            overflow-y: auto;

            > .popView-headerView {
                line-height: 40px;
                height: 60px;
                font-family: PingFangSC-Semibold;
                font-size: 17px;
                color: #333333;
                text-align: center;
                padding: 10px 0 5px 0;
            }

            > .btn-close {
                position: absolute;
                top: 10px;
                right: 5px;
                width: 40px;
                height: 40px;
                z-index: 100;
                background: url(https://static.bokao2o.com/wisdomCashier/images/Icon_X_Gray.png) center / 28px 28px no-repeat;
            }
        }
    }

    button {
        /*background: transparent;*/
        border: none;
        outline: none;
        box-sizing: border-box;
        cursor: pointer;
    }
</style>
