<template>
  <div class="accountingView content-index">
    <div class="topView">
      <div class="tView">
         <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="收入收支"
        ></el-page-header>
      </div>
      <div class="dateView">
        <el-date-picker
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
        @change="handleFind">
        </el-date-picker>
      </div>
      <div class="typeView">
        <el-select v-model="changeAccountingType" clearable placeholder="记账类型" @change="handleFind">
          <el-option
              v-for="item in changeAccountingTypeList"
              :key="item.id"
              :label="item.value"
              :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <!--      <div class="dateView">-->
      <!--        <el-button type="primary" @click="getMemberCardStatistics">确认</el-button>-->
      <!--      </div>-->
      <el-button type="primary" class="btn-add" @click="addStoreAccounting">添加</el-button>
    </div>
    <div class="headerView">
      <div class="headerItem">门店编号</div>
      <div class="headerItem">单号</div>
      <div class="headerItem">记账类别</div>
      <div class="headerItem">单据日期</div>
      <div class="headerItem">总金额</div>
      <div class="headerItem">登记人员</div>
      <div class="editView">操作</div>
    </div>
    <div class="listView" style="height: 539px;" v-infinite-scroll="loadOrderArr" infinite-scroll-distance="10"
         infinite-scroll-disabled="disableLoadOrderArr">
      <div class="listItem" v-for="(item,index) in orderArr">
        <div class="listItem">{{ item.storeName }}</div>
        <div class="listItem">{{ item.accountingId }}</div>
        <div class="listItem">{{ item.accountingTypeName }}</div>
        <div class="listItem">{{ item.registerDate }}</div>
        <div class="listItem">{{ item.totalAmount }}元</div>
        <div class="listItem">{{ item.storeUserName }}</div>
        <div class="editView">
          <!--          <button class="btn-blue">编辑</button>-->
          <el-button type="primary" @click="getStoreAccounting(item.accountingId)">编辑</el-button>
        </div>
      </div>
      <div v-if="loadingOrderArr" class="moreItem">加载中...</div>
      <div v-if="noMoreOrderArr" class="moreItem">没有更多订单哦</div>

    </div>
    <pop-view id="accounting-AddPopView" :show="showModal" width="900px" top="10%" title="添加收入支出"
              @cancel="showModal = false">
      <div class="btnView">
        <!--        <button class="btn-saveAndAdd">保存并继续</button>-->
        <button class="btn-save btn-blue btn-audio" v-if="accountingId === ''" @click="saveStoreAccounting">保存</button>
        <button class="btn-save btn-blue btn-audio" v-else @click="updateStoreAccounting">编辑</button>
      </div>
      <div class="addView" v-loading="addLoading" element-loading-text="加载中">
        <div class="topView">
          <!--          <div>-->
          <!--            <label>登记日期</label>-->
          <!--            <label class="dateView">2020-12-21</label>-->
          <!--          </div>-->
          <div>
            <label>记账类型</label>
            <el-select v-model="basicInformation.accountingType" placeholder="请选择">
              <el-option
                  v-for="item in accountingTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="headerView">
          <div class="tView">账单明细
            <button class="btn-add btn-blue" @click="addBillDetail"></button>
          </div>
          <div class="subHeaderView">
            <div>类别</div>
            <div>单位</div>
            <div>单价</div>
            <div>数量</div>
            <div>金额</div>
            <div>报销人</div>
            <div class="remarkView">备注</div>
            <div>是否虚拟款</div>
          </div>
        </div>
        <div class="listView" style="min-height: 294px;">
          <div class="listItem" v-for="(item,index) in basicInformation.storeAccountingDetailList">
            <div class="selectView">
              <el-select v-model="item.categoryId" placeholder="请选择">
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="selectView">
              <el-select v-model="item.unitId" placeholder="请选择">
                <el-option
                    v-for="item in unitList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="inputView">
              <input placeholder="单价" type="number" v-model="item.unitPrice"></input>
            </div>
            <div class="inputView">
              <input placeholder="数量" type="number" v-model="item.quantity"></input>
            </div>
            <div class="inputView">
              <div class="priceView">{{ parseFloat(item.unitPrice * item.quantity).toFixed(2)}}</div>
            </div>
            <div class="inputView">
              <input disabled :placeholder="storeUserInfo.realName" type="number"></input>
            </div>
            <div class="inputView remarkView">
              <input placeholder="填写备注" v-model="item.remark"></input>
            </div>
            <div class="checkView">
              <el-checkbox v-model="item.virtual"></el-checkbox>
            </div>
            <button class="btn-del" @click="removeBillDetail(index)"></button>
          </div>

        </div>

      </div>
    </pop-view>

  </div>
</template>

<script>
import {dateFormat} from '@/utils/date'
import {getStoreList} from '@/api/store/user'
import {
  saveStoreAccounting,
  storeAccountingPage,
  getStoreAccounting,
  updateStoreAccounting
} from '@/api/store/accounting'
import PopView from '@/components/PopView'
import {mapGetters} from 'vuex'

export default {
  name: "index",
  components: {
    PopView
  },
  data() {
    return {
      test: false,
      // 订单相关
      orderRecord: {},
      // 会员
      memberCardUserRecord: {},
      // 退款
      refundRecord: {},
      timeStart: dateFormat(new Date()),
      timeEnd: dateFormat(new Date()),
      userId: 0,
      tenantId: 0,
      storeList: [],
      dateRange: null,
      radio2: '1',
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, start]);
            }
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      printStartTime: '',
      printEndTime: '',
      showModal: false,
      
      // 记账类型
      changeAccountingTypeList: [
        {
          id: 0,
          value: '全部'
        },
        {
          id: 1,
          value: '支出'
        },
        {
          id: 2,
          value: '额外收入'
        }
      ],
      changeAccountingType: 0,
      accountingTypeList: [
        {
          id: 1,
          value: '支出'
        },
        {
          id: 2,
          value: '额外收入'
        }
      ],
      // 类型
      categoryList: [{
        id: 1,
        value: '水费'
      },
        {
          id: 2,
          value: '电费'
        },
        {
          id: 3,
          value: '广告费'
        },
        {
          id: 4,
          value: '退款'
        },
        {
          id: 5,
          value: '错账抵充'
        },
        {
          id: 6,
          value: '餐费'
        },
        {
          id: 7,
          value: '交通费'
        },
        {
          id: 8,
          value: '员工借支'
        },
        {
          id: 9,
          value: '配送费'
        }
      ],
      // 单位
      unitList: [
        {
          id: 1,
          value: '瓶'
        },
        {
          id: 2,
          value: '盒'
        },
        {
          id: 3,
          value: '份'
        },
        {
          id: 4,
          value: '克'
        },
        {
          id: 5,
          value: '千克'
        },
        {
          id: 6,
          value: '升'
        },
        {
          id: 7,
          value: '毫升'
        },
        {
          id: 8,
          value: '双'
        },
        {
          id: 9,
          value: '套'
        },
      ],
      //购房人基本信息属性
      basicInformation: {
        accountingId: '',
        accountingType: 1,
        // 账单详情
        storeAccountingDetailList: [
          {
            categoryId: "",
            unitId: "",
            unitPrice: "",
            quantity: '',
            amount: '',
            remark: '',
            virtual: false,
          }
        ],
      },
      addLoading: false,
      storeAccountingList: [],
      accountingId:'',
      orderArr: [],
      loadingOrderArr: false,
      noMoreOrderArr: false,
      currentPage: 1,
      pageSize: 20,
      total: 0, // 总数量
      moreList: [],


    }
  },
  computed: {
    disableLoadOrderArr() {
      return this.loadingOrderArr || this.noMoreOrderArr
    },
    ...mapGetters({storeUserInfo: 'userInfo'}),
  },
  created() {
    // this.getStoreAccountingPage()
  },
  methods: {
    dateFormat,
    GetDateStr(AddDayCount) {
      let t = new Date();
      let t_s = t.getTime();
      t.setTime(t_s + 1000 * 60 * 60 * 24 * AddDayCount);
      return t;
    },
    openFullScreen2() {
      this.test = true
      setTimeout(() => {
        this.test = false
      }, 2000);
    },
    handleFind: function (flag) {
      this.orderArr = [];
      this.currentPage = 1;
      this.loadOrderArr(flag)
    },
    // 门店的
    getStoreAccountingPage() {
      this.test = true
      const params = new URLSearchParams()
      params.append('beginTime', this.dateRange === null ? dateFormat(this.timeStart) : this.dateRange[0])
      params.append('endTime', this.dateRange === null ? dateFormat(this.timeEnd) : this.dateRange[1])
      params.append('accountingType', this.changeAccountingType)
      storeAccountingPage(params).then(response => {
        console.log(response);
        this.storeAccountingList = response.data.data.records
      })
    },
    loadOrderArr(flag) {
      // console.log(this.timeStart);
      console.log('触发无限滚动');
      var _this = this;
      this.loadingOrderArr = true;
      // 在这里请求api，获取数据后 push给orderArr
      console.log(_this.currentPage);
      const params = new URLSearchParams()
      params.append('beginTime', this.dateRange === null ? dateFormat(this.timeStart) : this.dateRange[0])
      params.append('endTime', this.dateRange === null ? dateFormat(this.timeEnd) : this.dateRange[1])
      params.append('accountingType', this.changeAccountingType)
      storeAccountingPage(params).then(response => {
        console.log(response);
        _this.moreList = response.data.data.records;
        _this.total = response.data.data.total;
        if (_this.moreList.length === 0) {
          _this.noMoreOrderArr = true;
        } else {
          _this.noMoreOrderArr = false;
          _this.currentPage++;
          _this.moreList.forEach(function (item) {
            _this.orderArr.push(item);
          })
        }
        if (_this.orderArr.length >= _this.total) {
          _this.noMoreOrderArr = true
        }
        _this.loadingOrderArr = false;
      })
    },
    getStoreList(username) {
      const that = this
      if (username) {
        const params = new URLSearchParams()
        params.append('username', username)
        getStoreList(params).then(response => {
          console.log(response);
          // this.storeList = response.data.data.records
          if (response.data.code === 0) {
            that.storeList = response.data.data
          }
        })
      }
    },
    changeQuery(val) {
      // 本人查询
      if (val === 2) {
        this.membercardStatistics()
      } else {
        this.userId = 0
        this.getMemberCardStatistics()
      }
    },

    getTime() {
      var date1 = new Date();
      var year = date1.getFullYear();
      var month = date1.getMonth() + 1;
      var day = date1.getDate();
      var hours = date1.getHours();
      var minutes = date1.getMinutes();
      var seconds = date1.getSeconds();
      return year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds
    },
    //新增账单明细
    addBillDetail() {
      const that = this;
      that.basicInformation.storeAccountingDetailList.push({
        categoryId: "",
        unitId: "",
        unitPrice: "",
        quantity: '',
        amount: '',
        remark: '',
        virtual: false,
      });
    },
    //删除账单明细
    removeBillDetail(index) {
      if (index >= 0 && this.basicInformation.storeAccountingDetailList.length > 1) {
        this.basicInformation.storeAccountingDetailList.splice(index, 1);
      } else {
        this.$message.error('最后一个啦 别删我');
      }
    },
    // 保存
    saveStoreAccounting() {
      this.addLoading = true
      console.log(this.basicInformation)
      saveStoreAccounting(this.basicInformation).then(response => {
        console.log(response);
        if (response.data.code === 0) {
          this.addLoading = false
          this.showModal = false
          this.handleFind()
          this.$message.success('操作成功');
        }
      })
    },
    // 更新
    updateStoreAccounting() {
      this.addLoading = true
      console.log(this.basicInformation)
      updateStoreAccounting(this.basicInformation).then(response => {
        console.log(response);
        if (response.data.code === 0) {
          this.addLoading = false
          this.showModal = false
          this.handleFind()
          this.$message.success('操作成功');
        }
      })
    },
    getStoreAccounting(id) {
      this.accountingId = id
      getStoreAccounting(id).then(response => {
        console.log(response);
        if (response.data.code === 0) {
          this.basicInformation = response.data.data
          this.showModal = true
        }
      })
    },
    addStoreAccounting(){
      this.accountingId = ''
	  // this.basicInformation.storeAccountingDetailList{
   //      categoryId: "",
   //      unitId: "",
   //      unitPrice: "",
   //      quantity: '',
   //      amount: '',
   //      remark: '',
   //      virtual: false,
   //    };
	  this.basicInformation.storeAccountingDetailList.forEach(item=>{
		  item.categoryId = '';
		  item.unitId = '';
		  item.unitPrice = '';
		  item.quantity = '';
		  item.amount = '';
		  item.remark = '';
		  item.virtual = false;
	  })
      this.showModal = true
    }

  }
}
</script>

<style scoped lang="scss">
.accountingView {
  overflow: auto;

  > .topView {
    // position: fixed;
    // top: 0;
    // left: 70px;
    // right: 0;
    padding: 17.5px 20px;
    overflow: hidden;
    // z-index: 100;
    background: #fff;

    > .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 24px;
      font-family: PingFang-SC-Bold;
      line-height: 40px;
    }

    > .typeView {
      float: left;
      width: 130px;
    }

    > .dateView {
      float: left;
      //margin-left: 35px;
      padding-left: 46px;
      height: 40px;
      line-height: 40px;
    }

    > .btn-add {
      float: right;
      width: 90px;
      height: 40px;
      //line-height: 40px;
    }

    > div {
      margin-right: 15px;
    }
  }

  > .headerView {
    position:relative;
    // position: fixed;
    display: flex;
    // top: 75px;
    // left: 70px;
    // right: 0;
    padding: 0 102px 5px 30px;
    line-height: 40px;
    overflow: hidden;
    // z-index: 100;
    font-size: 14px;
    color: #999;
    text-align: center;

    > .headerItem {
      flex: 1;
    }

    > .editView {
      position: absolute;
      right: 30px;
      width: 72px;
      font-size: 14px;
      color: #999;
      text-align: center;
    }
  }

  > .listView {
    overflow-x: auto;
    overflow-y: auto;
    padding: 0 30px 30px 30px;
    min-height: 300px;

    > .listItem {
      position: relative;
      display: flex;
      font-size: 14px;
      background: #fff;
      text-align: center;
      padding: 15px 72px 15px 0;
      line-height: 20px;
      color: #333;
      cursor: pointer;

      > div {
        flex: 1;
      }

      > .editView {
        position: absolute;
        width: 72px;
        height: 36px;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;

        > button {
          width: 65px;
          height: 36px;
          line-height: 10px;
          //border-radius: 4px;
          //font-size: 14px;
        }
      }
    }
    > .moreItem {
      margin-top: 5px;
      line-height: 40px;
      font-size: 13px;
      color: #3370ff;
      text-align: center;
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }
  }


}

label {
  cursor: default;
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn-blue {
  background: #3370FF;
  font-size: 16px;
  border-radius: 6px;
  color: #fff;
}

//button {
//  background: transparent;
//  border: none;
//  outline: none;
//  box-sizing: border-box;
//  cursor: pointer;
//}

#accounting-AddPopView /deep/ .btn-close {
  left: 10px;
  right: auto;
}

#accounting-AddPopView .btnView {
  position: absolute;
  top: 10px;
  right: 15px;

  > .btn-del {
    width: 90px;
    height: 40px;
    line-height: 40px;
    z-index: 100;
    background: #fff;
    color: #FF5E56;
    font-size: 15px;
  }

  > .btn-save {
    width: 65px;
    height: 40px;
    line-height: 40px;
    z-index: 100;
    font-size: 15px;
  }

  > .btn-saveAndAdd {
    width: 100px;
    padding: 0;
    height: 40px;
    line-height: 40px;
    z-index: 100;
    background: #fff;
    color: #3370ff;
    font-size: 15px;
  }
}

#accounting-AddPopView .addView {
  padding: 0 15px 15px 15px;

  > .topView {
    padding: 10px 0 0 0;
    border-bottom: 0.5px solid rgba(220, 220, 220, 0.7);
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 210px;
      margin-right: 10px;
      background: #f8f8f8;
      height: 48px;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 15px;
      padding: 0 0 0 12px;

      > label {
        display: block;
        min-width: 70px;
        font-size: 14px;
        color: #666;
        line-height: 20px;
        padding: 14px 10px 14px 0;
      }

      > div {
        flex: 1;
        line-height: 48px;
        text-align: right;
        padding-right: 12px;
      }

      /deep/ > .el-select input {
        line-height: 48px;
        height: 48px;
        background: #f8f8f8;
        border: none;
        font-size: 14px;
        color: #333;
        text-align: right;
        padding-right: 30px;
        padding-left: 0;
      }
    }
  }

  > .headerView {
    > .tView {
      line-height: 36px;
      padding: 15px 0;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      color: #333;
      overflow: hidden;

      > button {
        float: right;
        width: 65px;
        height: 36px;
        line-height: 36px;
        background: #3370ff url(https://static.bokao2o.com/wisdomCashier/Def_Icon_Add.png) center / 20px no-repeat;
        border-radius: 4px;
      }

    }

    > .subHeaderView {
      overflow: hidden;
      padding: 10px 0 15px 30px;

      > div {
        float: left;
        width: 100px;
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 20px;
      }

      > div.remarkView {
        width: 140px;
      }
    }
  }

  > .listView > .listItem {
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    padding-left: 30px;

    > div {
      float: left;
      width: 100px;
    }

    > div.selectView {
      padding-right: 12px;

      > .el-select {
        width: 88px;
        padding-right: 0;
        background: #f8f8f8;
        border-radius: 5px;
        overflow: hidden;
      }

      /deep/ > .el-select input {
        width: 88px;
        text-align: center;
        line-height: 48px;
        height: 48px;
        background: #f8f8f8;
        border: none;
        font-size: 14px;
        color: #333;
        padding-right: 25px;
      }
    }

    > div.inputView {
      padding-right: 12px;

      > input {
        text-align: center;
        width: 88px;
        height: 48px;
        box-sizing: border-box;
        padding: 9px 0;
        line-height: 30px;
        background: #f8f8f8;
        border-radius: 5px;
        overflow: hidden;
        border: none;
        outline: none;
        font-size: 14px;
        color: #333;
      }

      > div {
        text-align: center;
        width: 88px;
        height: 48px;
        box-sizing: border-box;
        padding: 9px 0;
        line-height: 30px;
        background: #f8f8f8;
        border-radius: 5px;
        overflow: hidden;
        border: none;
        outline: none;
        font-size: 14px;
        color: #333;
      }
    }

    > div.inputView.remarkView {
      width: 140px;

      > input {
        width: 140px;
      }
    }

    > div.checkView {
      height: 48px;
      //background: transparent url(https://static.bokao2o.com/wisdomCashier/images/Icon_Select_N.png) center / 24px no-repeat;
      text-align: center;
      line-height: 48px;
    }

    > .btn-del {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 48px;
      background: transparent url(https://static.bokao2o.com/wisdomCashier/images/Icon_Delete_Gray.png) center / 24px no-repeat;
    }

    > div.checkView.select {
      background: transparent url(https://static.bokao2o.com/wisdomCashier/images/Icon_Select_S.png) center / 24px no-repeat;
    }
  }
}
</style>
