import request from '@/utils/request'

export function getStoreList(parms) {
  return request({
    url: '/sys/storeUser/getStore',
    method: 'get',
    params: parms,
  })
}
export function getUserInfo(params) {
  return request({
    url: '/member/user/getUserInfo',
    method: 'get',
    params: params,
  })
}

export function getUserInfoRefundBalance(params) {
  return request({
    url: '/member/user/getUserInfoRefundCard',
    method: 'get',
    params: params,
  })
}
// 根据手机号或者卡号查询会员用户卡集合及其优惠金额
export function getUserInfoRefundCard(params) {
  return request({
    url: '/member/user/getUserRefundCardList',
    method: 'get',
    params: params,
  })
}

// 修改密码
export function updatePassword(params) {
  return request({
    url: '/sys/storeuser/updatePassword',
    method: 'post',
    params: params,
  })
}

//修改标签
export function putTag(data) {
  return request({
    url: 'member/user/settingUserLabel',
    method: 'PUT',
    data,
  })
}

//手机号验证
export function forgetPass(params) {
  return request({
    url: 'sys/storeUser/forgetPass',
    method: 'post',
    data:params,
  })
}
//给手机号发送验证码
export function sendCode(params) {
  return request({
    url: 'sys/mobile/sendCode/' + params,
    method: 'post',
  })
}


